import styled from '@emotion/styled';
import { type FC } from 'react';
import { SystemIcon, Typography, type SystemIconId } from '@volvo/vce-uikit';
import { theme } from '../../../theme';

type ExplanationProps = {
  readonly title?: string;
  readonly texts: string[];
  readonly icon?: SystemIconId;
};

const SpacedTypography = styled(Typography)`
  margin-bottom: ${theme.map.layout.gutter.small};
`;

const ExplanationContainer = styled.div`
  color: ${theme.colors.textDisabled};
  margin-top: ${theme.map.layout.gutter.medium};
  margin-bottom: ${theme.map.layout.gutter.medium};
  padding: 16px 16px 16px 48px;
  background-color: #d5e6ff;
  border: 1px solid ${theme.colors.borderHighlight};
  border-radius: ${theme.map.design.borderRadius.medium};
  position: relative;

  .explanation-icon {
    position: absolute;
    left: 12px;
    top: 12px;
  }
`;

export const Explanation: FC<ExplanationProps> = ({ title, texts, icon }) => (
  <ExplanationContainer>
    {icon && (
      <SystemIcon
        className="explanation-icon"
        style={{ color: theme.colors.borderHighlight }}
        icon={icon}
      />
    )}
    {title && <SpacedTypography variant="subtitle2">{title}</SpacedTypography>}
    {texts?.map((text) => (
      <SpacedTypography key={text} variant="caption2">
        {text}
      </SpacedTypography>
    ))}
  </ExplanationContainer>
);
