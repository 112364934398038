import styled from '@emotion/styled';
import type { ChangeEvent, ReactNode } from 'react';
import {
  useController,
  type Control,
  type FieldValues,
  type Path,
  type UseControllerProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox as VCECheckbox } from '@volvo/vce-uikit';
import { ErrorMessage } from '../error/ErrorMessage';

interface CheckboxProps<T extends FieldValues> {
  name: Path<T>;
  label: string | ReactNode;
  control: Control<T>;
  rules?: UseControllerProps['rules'];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hideErrors?: boolean;
}

const StyledLabel = styled.label`
  @hover: {
    cursor: pointer;
  }
  margin-left: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8px;
`;

export function Checkbox<T extends FieldValues>({
  name,
  label,
  control,
  rules,
  onChange,
  hideErrors,
}: CheckboxProps<T>) {
  const { t } = useTranslation();
  const {
    field: { onChange: fieldChange, value },
    fieldState: { error },
  } = useController<T>({
    name,
    control,
    rules,
  });

  return (
    <CheckboxWrapper>
      <VCECheckbox
        id={name}
        label={''}
        checked={value}
        onChange={(e) => {
          fieldChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
      ></VCECheckbox>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      {!hideErrors && error && <ErrorMessage text={error.message ?? t('error')} />}
    </CheckboxWrapper>
  );
}
