import { memo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementType } from '@volvo/vce-package-site-mapcommon';
import { Typography } from '@volvo/vce-uikit';
import { useMapContext } from '../../../../context/map';
import { useNavigationContext } from '../../../../context/navigation';
import { type SourceType } from '../../../../types';
import { elementTypeMapping, zoneTypeMapping } from '../../helpers';
import { CheckBox, VisibilityToggler } from './styles';

type Props = { sourceType: SourceType };

const LayerVisibilityItemComponent: FC<Props> = ({ sourceType }) => {
  const { visibility, setVisibility } = useMapContext();
  const { selected } = useNavigationContext();
  const { t } = useTranslation();

  const onVisibilityChange = (sourceType: string, visible: boolean) => {
    setVisibility({ [sourceType]: visible });
  };

  const selectedFeatureBelongsToSourceType = (sourceType: SourceType) => {
    if (selected?.type !== 'feature' || !selected?.feature) {
      return false;
    }

    if (selected.feature.properties.element_type === ElementType.ZONE) {
      return zoneTypeMapping[sourceType] === selected.feature.properties.zone_type;
    } else {
      return elementTypeMapping[sourceType] === selected.feature.properties.element_type;
    }
  };

  const disabled = selectedFeatureBelongsToSourceType(sourceType);

  return (
    <VisibilityToggler>
      <Typography variant="body">{t(`visibility-toggler.${sourceType}`)}</Typography>
      <CheckBox
        type="checkbox"
        checked={visibility[sourceType]}
        onChange={(evt) => {
          onVisibilityChange(sourceType, evt.target.checked);
        }}
        disabled={disabled}
      />
    </VisibilityToggler>
  );
};

export const LayerVisibilityItem = memo(LayerVisibilityItemComponent);
