import type { Position } from 'geojson';
import { forwardRef, useMemo, type ForwardRefExoticComponent, type RefAttributes } from 'react';
import { SystemIcon, Typography } from '@volvo/vce-uikit';
import type { CustomFeatureZone } from '../../../types';
import { CustomPopup } from './custom-popup/CustomPopup';
import { NarrowLane } from './narrow-lane/NarrowLane';
import { SpeedLimit } from './speed-limit/SpeedLimit';
import { Container, List, Row } from './style';

export type PopupState = { center: Position; feature: CustomFeatureZone };

type Props = {
  popup: PopupState;
  onClick?: () => void;
  offset?: mapboxgl.Offset;
  anchor?: mapboxgl.Anchor;
};

export const ZonePopup: ForwardRefExoticComponent<RefAttributes<mapboxgl.Popup> & Props> =
  forwardRef(({ popup, onClick, offset, anchor }, ref) => {
    const columns = useMemo(
      () =>
        !!popup.feature.properties.maxSpeedMs && !!popup.feature.properties.narrowLane
          ? 3
          : !!popup.feature.properties.maxSpeedMs || !!popup.feature.properties.narrowLane
            ? 2
            : 1,
      [popup.feature.properties.maxSpeedMs, popup.feature.properties.narrowLane],
    );

    return (
      <CustomPopup
        longitude={popup.center[0]}
        latitude={popup.center[1]}
        closeButton={false}
        maxWidth="400px"
        ref={ref}
        offset={offset}
        anchor={anchor}
      >
        <Container onClick={onClick} style={{ cursor: 'pointer' }}>
          <Row columns={columns}>
            {popup.feature.properties.maxSpeedMs ? (
              <SpeedLimit maxSpeed={popup.feature.properties.maxSpeedMs} />
            ) : null}
            {popup.feature.properties.narrowLane ? <NarrowLane /> : null}
            <Typography variant="subtitle2">{popup.feature.properties?.name}</Typography>
          </Row>
          <List>
            {popup.feature.properties?.materialVariants?.length
              ? popup.feature.properties.materialVariants.map(({ id, longName }) => (
                  <li key={id}>
                    <Row columns={columns}>
                      <SystemIcon icon="material" size={16} style={{ justifySelf: 'center' }} />
                      <Typography>{longName}</Typography>
                    </Row>
                  </li>
                ))
              : null}
          </List>
        </Container>
      </CustomPopup>
    );
  });
