import styled from '@emotion/styled';
import { type CSSProperties } from 'react';

export const MapContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const mapStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  height: '100%',
  zIndex: 1,
};
