import { filter, groupBy, map, pipe, prop, sort } from 'ramda';
import { useState, type FC } from 'react';
import {
  type Control,
  type FieldValues as HookFieldValues,
  type Validate,
  type ValidateResult,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementType } from '@volvo/vce-package-site-mapcommon';
import { SystemIcon, toast, Tooltip, Typography } from '@volvo/vce-uikit';
import { useFeaturesContext } from '../../../../../context/features';
import { useFlowsContext } from '../../../../../context/flows';
import { useSiteContext } from '../../../../../context/site';
import {
  MetricDefinitionType,
  SiteMetricDefinitionFilter,
  useQuerySiteMetricDefinitions,
  type FragmentSiteMetricDefinition,
} from '../../../../../gql-types/generated-types-super-graph';
import type { CustomFeaturePOI } from '../../../../../types';
import { DropdownField } from '../../../../common/dropdown-field/DropdownField';
import { GqlErrorDisplay } from '../../../../common/error/GqlErrorDisplay';
import { Explanation } from '../../../../common/explanation/Explanation';
import { CenteredSpinner } from '../../../../common/loading/CenteredSpinner';
import { type FieldValues } from '../types';
import { Container } from './styles';

type Props = {
  control: Control<FieldValues>;
  defaultValues: Partial<FieldValues>;
  flowId?: string;
};

interface Option {
  text: string;
  value: string;
}

export const Metrics: FC<Props> = ({ control, defaultValues, flowId }) => {
  const { t } = useTranslation();
  const { siteId } = useSiteContext();
  const { flowCollection } = useFlowsContext();
  const { featureCollection } = useFeaturesContext();

  const [infoRef, setInfoRef] = useState<HTMLElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const response = useQuerySiteMetricDefinitions({
    variables: { siteId, filterBy: { status: SiteMetricDefinitionFilter.All } },
    onError: (error) => toast.error(<GqlErrorDisplay errors={error.graphQLErrors} />),
  });

  if (response.loading) return <CenteredSpinner />;

  if (!response.data?.sites?.site?.siteMetricDefinitions?.edges) {
    return null;
  }

  const poisWithMetrics = featureCollection.filter(
    (f) =>
      f.properties.element_type === ElementType.POINT_OF_INTEREST &&
      f.properties.siteMetricDefinitions,
  ) as CustomFeaturePOI[];

  const poiMetricIds = poisWithMetrics
    .map((f) => f.properties.siteMetricDefinitions?.map(({ id }) => id))
    .flat()
    .filter((x) => x != undefined);

  const grouped = pipe(
    filter<{ node: FragmentSiteMetricDefinition }>(({ node }) => {
      return (
        (!node.materialFlow || node.materialFlow.id === flowId) &&
        !poiMetricIds.find((x) => x === node.id) &&
        !featureCollection.find(
          (f) =>
            f.properties.element_type === ElementType.POINT_OF_INTEREST &&
            f.properties.siteMetricDefinitions?.[0]?.id === node.id,
        )
      );
    }),
    map<{ node: FragmentSiteMetricDefinition }, FragmentSiteMetricDefinition>(({ node }) => node),
    sort((a, b) => ((a?.name || '').toLowerCase() > (b?.name || '').toLowerCase() ? 1 : -1)),
    groupBy(prop('type')),
  )(response.data.sites.site.siteMetricDefinitions.edges);

  if (!grouped) return null;

  const validate: Validate<string, HookFieldValues> = (value): ValidateResult => {
    if (!value) return true;

    const result = Boolean(
      featureCollection.find(
        (f) =>
          f.properties.element_type === ElementType.POINT_OF_INTEREST &&
          f.properties.siteMetricDefinitions?.[0]?.id === value,
      ),
    );

    if (result) return false;

    return Boolean(
      !flowCollection.find((x) => {
        if (x.id === flowId) return false;
        return x.siteMetricDefinitions.find(
          (def) =>
            def.id === value &&
            (def.type === MetricDefinitionType.LevelPercent ||
              def.type === MetricDefinitionType.LevelVolume),
        );
      }),
    );
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1">{t('details-panel.flow.metrics.title')}</Typography>

        <div ref={(ref) => setInfoRef(ref)}>
          <SystemIcon icon={'info'} />
        </div>
        <Tooltip
          reference={infoRef}
          open={tooltipOpen}
          setOpen={setTooltipOpen}
          placement={'left'}
          offset={10}
        >
          {t('details-panel.flow.metrics.info-text')}
        </Tooltip>
      </div>

      <DropdownField
        label={t('details-panel.flow.metrics.STATUS_INDICATOR')}
        name="indicatorMetricId"
        control={control}
        initialValue={defaultValues.indicatorMetricId}
        options={
          grouped?.STATUS_INDICATOR
            ? [{ text: '', value: '' }].concat(
                grouped.STATUS_INDICATOR.map(
                  ({ id, name, description }): Option => ({
                    text: `${name}${description ? ` (${description})` : ''}`,
                    value: id,
                  }),
                ),
              )
            : []
        }
      />
      <Explanation icon={'info'} texts={[t('details-panel.flow.metrics.indicator-info-text')]} />

      <DropdownField
        label={t('details-panel.flow.metrics.LEVEL_PERCENT')}
        name="levelMetricId"
        control={control}
        initialValue={defaultValues.levelMetricId}
        options={
          grouped.LEVEL_PERCENT || grouped.LEVEL_VOLUME
            ? [{ text: 'None', value: '' }].concat(
                [...(grouped.LEVEL_PERCENT || []), ...(grouped.LEVEL_VOLUME || [])].map(
                  ({ id, name, description }): Option => ({
                    text: `${name}${description ? ` (${description})` : ''}`,
                    value: id,
                  }),
                ),
              )
            : []
        }
        rules={{ validate }}
      />
      {control.getFieldState('levelMetricId').invalid ? (
        <Typography variant="subtitle2" color="textError">
          {t('details-panel.flow.form.site-metric-in-use')}
        </Typography>
      ) : null}
      <Explanation icon={'info'} texts={[t('details-panel.flow.metrics.level-info-text')]} />

      <DropdownField
        label={t('details-panel.flow.metrics.MASS_FLOW_RATE')}
        name="flowrateMetricId"
        control={control}
        initialValue={defaultValues.flowrateMetricId}
        options={
          grouped?.MASS_FLOW_RATE
            ? [{ text: 'None', value: '' }].concat(
                grouped.MASS_FLOW_RATE.map(
                  ({ id, name, description }): Option => ({
                    text: `${name}${description ? ` (${description})` : ''}`,
                    value: id,
                  }),
                ),
              )
            : []
        }
      />
      <Explanation icon={'info'} texts={[t('details-panel.flow.metrics.flow-rate-info-text')]} />
    </Container>
  );
};
