import { type Theme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Row } from 'antd';
import { isEmpty, lowerCase } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, SystemIcon, Table, type SystemIconId } from '@volvo/vce-uikit';
import { theme } from '../../../theme';
import { DateRangePicker, type DateRange } from '../../common/date-range-picker/DateRangePicker';
import { EmptyState } from '../../common/empty-state/EmptyState';
import { Icon, resolveColor, resolveIcon, resolveIconFilling, type IconColor } from './icons';
import {
  CenterContainer,
  CenterContent,
  ColumnCellContainer,
  FilterContainer,
  FilterIconDiv,
  FilterStylingContainer,
} from './styles';

interface ColumnConfig {
  label: string;
  key: string;
  iconKey?: string;
  enableSorting?: boolean;
  size?: number;
  isIconColumn?: boolean;
  icon?: SystemIconId;
  iconSize?: 16 | 24 | 32 | 48 | 72;
  iconColor?: IconColor;
  align?: string;
  warningTextIfEmpty?: string;
  meta?: any;
  formatValue?: (value: any) => any;
  title?: (value: any) => string;
}

export type EventTableEvent = {
  id: string;
  timeStampStr: string;
  timestamp: Date;
  message: string;
  icon?: string;
};

type EventTableProps = {
  readonly events: EventTableEvent[] | null;
  readonly error: { message: string } | undefined;
  readonly loading: boolean;
  readonly theme?: Theme;
  readonly columnConfig?: ColumnConfig[];
  readonly noMoreEvents: boolean;
  readonly onLoadMoreClick?: () => void;
  readonly onDateRangeChange?: (dateRange: DateRange | null) => void;
};

export const EventTable = ({
  events,
  error,
  loading,
  columnConfig,
  noMoreEvents,
  onLoadMoreClick,
  onDateRangeChange,
}: EventTableProps) => {
  const [filteredEvents, setFilteredEvents] = useState<EventTableEvent[] | null>(null);
  const { t } = useTranslation();
  const [showFilterContainer, setShowFilterContainer] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [loadMore, setLoadMore] = useState(false);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();

    const config: ColumnConfig[] = columnConfig ?? [
      {
        label: t('table.columns.date'),
        key: 'timestamp',
        enableSorting: false,
        size: 70,
        meta: {
          cellOverflow: true,
        },
        formatValue: (value) => DateTime.fromJSDate(value).toRelative({ locale: 'en' }),
        title: (value) => DateTime.fromJSDate(value).toLocaleString(DateTime.DATETIME_FULL),
      },
      {
        label: t('table.columns.message'),
        key: 'message',
        enableSorting: false,
        meta: {
          cellOverflow: true,
        },
        iconKey: 'icon',
        iconSize: 16,
      },
    ];

    return config.map((c) => {
      if (c.isIconColumn) {
        return columnHelper.accessor(c.key, {
          id: c.key,
          header: () => <>{c.label}</>,
          enableSorting: c.enableSorting,
          size: c.size,
          meta: c.meta,
          cell: ({ getValue }) => {
            if (!getValue() && !c.icon) return null;

            return (
              <CenterContent>
                <SystemIcon
                  icon={c.icon ? c.icon : resolveIcon(lowerCase(getValue()))}
                  filled={resolveIconFilling(lowerCase(getValue()))}
                  size={c.iconSize ? c.iconSize : 32}
                  style={
                    c.iconColor
                      ? { color: c.iconColor }
                      : { color: resolveColor(lowerCase(getValue())) }
                  }
                />
                {c.iconColor}
              </CenterContent>
            );
          },
        });
      }

      return columnHelper.accessor(c.key, {
        id: c.key,
        header: () => <>{c.label}</>,
        enableSorting: c.enableSorting,
        size: c.size,
        meta: c.meta,
        cell: ({ row, getValue }) => {
          let cellText = getValue();
          let emptyWarning = false;

          if (!cellText && c.warningTextIfEmpty) {
            cellText = c.warningTextIfEmpty;
            emptyWarning = true;
          }

          return (
            <ColumnCellContainer>
              {c.iconKey && row.original[c.iconKey] && (
                <SystemIcon
                  icon={resolveIcon(lowerCase(row.original[c.iconKey]))}
                  size={c.iconSize ? c.iconSize : 32}
                  filled={resolveIconFilling(lowerCase(row.original[c.iconKey]))}
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '4px',
                    color: c.iconColor
                      ? c.iconColor
                      : resolveColor(lowerCase(row.original[c.iconKey])),
                  }}
                />
              )}

              <div style={{ display: 'inline' }} title={c.title ? c.title(cellText) : ''}>
                {emptyWarning ? (
                  <Row>
                    <SystemIcon
                      icon={Icon.WARNING}
                      size={16}
                      style={{
                        color: theme.colors.textError,
                        width: '20px',
                        marginRight: '5px',
                      }}
                    />
                    {cellText}
                  </Row>
                ) : (
                  <>{c.formatValue ? c.formatValue(cellText) : cellText}</>
                )}
              </div>
            </ColumnCellContainer>
          );
        },
      });
    });
  }, [columnConfig, t]);

  useEffect(() => {
    if (!events) {
      return;
    }

    const fromDate = dateRange
      ? DateTime.fromJSDate(dateRange.from).startOf('day').setZone('utc')
      : null;

    const toDate = dateRange?.to
      ? DateTime.fromJSDate(dateRange.to).endOf('day').setZone('utc')
      : null;

    const filteredEvents = events.filter((event) => {
      if (!fromDate && !toDate) return true;
      const eventDate = DateTime.fromJSDate(event.timestamp).setZone('utc');
      if (fromDate && !toDate) return eventDate >= fromDate;
      if (!fromDate && toDate) return eventDate < toDate;
      if (fromDate && toDate) return Interval.fromDateTimes(fromDate, toDate).contains(eventDate);
      return false;
    });

    setFilteredEvents(filteredEvents);
  }, [dateRange, dateRange?.from, dateRange?.to, events]);

  const onLoadMoreInternalClick = () => {
    setLoadMore(true);
    onLoadMoreClick?.();
  };

  if (error) {
    return (
      <div style={{ color: theme.colors.textError }}>
        <h2>{t('error')}</h2>
        <p>{error.message}</p>
      </div>
    );
  }

  const hasEvents = filteredEvents && filteredEvents.length > 0;

  return (
    <div>
      {!events && !filteredEvents ? (
        <Spinner
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '50px 0',
          }}
        />
      ) : (
        <div>
          {!events?.length && isEmpty(dateRange) ? null : (
            <FilterContainer>
              <FilterStylingContainer showFilter={showFilterContainer}>
                <DateRangePicker
                  max={new Date()}
                  values={dateRange}
                  onChange={(range) => {
                    setDateRange(range ?? null);
                    if (onDateRangeChange) onDateRangeChange(range ?? null);
                  }}
                />
              </FilterStylingContainer>
              <FilterIconDiv>
                <Button
                  iconEnd={showFilterContainer ? 'close' : 'filter'}
                  variant="tertiary"
                  onClick={() => {
                    if (showFilterContainer) {
                      setDateRange(null);
                      if (onDateRangeChange) onDateRangeChange(null);
                      setFilteredEvents(events ?? []);
                    }

                    setShowFilterContainer((prevValue) => !prevValue);
                  }}
                >
                  {dateRange
                    ? t('table.filter.clear')
                    : showFilterContainer
                      ? t('table.filter.close')
                      : t('table.filter.filter')}
                </Button>
              </FilterIconDiv>
            </FilterContainer>
          )}
          {loading && !loadMore ? (
            <CenterContainer>
              <Spinner />
            </CenterContainer>
          ) : hasEvents ? (
            <>
              <Table
                fullWidth
                columns={columns}
                data={filteredEvents ?? events ?? []}
                style={{ overflowWrap: 'anywhere' }}
              />
              <div style={{ margin: '20px 0' }}>
                <Button
                  variant="secondary"
                  onClick={onLoadMoreInternalClick}
                  disabled={loading || noMoreEvents}
                  title={
                    noMoreEvents ? t('table.no-more-events-to-load') : t('table.load-more-button')
                  }
                >
                  {loading ? <Spinner size="small" /> : t('table.load-more-button')}
                </Button>
              </div>
            </>
          ) : (
            <CenterContainer>
              <EmptyState />
            </CenterContainer>
          )}
        </div>
      )}
    </div>
  );
};
