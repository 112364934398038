import styled from '@emotion/styled';
import { theme } from '../../../../theme';

export const Container = styled.div<{ leftPanelOpen: boolean }>`
  position: absolute;
  top: 105px;
  left: ${(props) => (props.leftPanelOpen ? '360px' : '10px')};
  z-index: 11;
`;

export const ControlPanel = styled.div`
  max-width: 320px;
  background: ${theme.colors.bg};
  padding: 12px;
  border-radius: 5px;
`;

export const SourceList = styled.ul`
  list-style: none;
  display: grid;
  gap: 8px;
`;

export const VisibilityToggler = styled.li`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
`;
