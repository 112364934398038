import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, SystemIcon } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../../../../../../context/navigation';
import { useZoneEditContext } from '../../../../../../../../context/zone-edit/ZoneEditContext';
import { useZonePropertiesEditContext } from '../../../../../../../../context/zone-properties-edit/ZonePropertiesEditContext';
import { Charger } from '../../../../charger/Charger';
import { ListItem } from '../../styles';

export const AddedChargers: FC = () => {
  const { t } = useTranslation();
  const { setDirty } = useNavigationContext();
  const { chargingTabState, setChargingTabState } = useZoneEditContext();
  const { materialVariantsAccordionOpen } = useZonePropertiesEditContext();

  if (!chargingTabState?.chargers?.length) {
    return null;
  }

  const onRemoveCharger = (chargerId: string) => {
    setDirty(true);
    setChargingTabState({
      chargers: chargingTabState.chargers.filter((charger) => charger.id !== chargerId),
    });
  };

  return (
    <Accordion
      headerTitle={`${t('details-panel.zone.charging.charging')} (${
        chargingTabState.chargers.length
      })`}
      headerIcon="lightning"
      open={materialVariantsAccordionOpen}
    >
      {chargingTabState.chargers.map((charger) => (
        <ListItem key={charger.id}>
          <Charger description={charger?.description} id={charger?.emobAssetId as string} />
          <SystemIcon
            style={{ cursor: 'pointer' }}
            icon="close"
            onClick={() => {
              onRemoveCharger(charger.id);
            }}
          />
        </ListItem>
      ))}
    </Accordion>
  );
};
