import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, Typography } from '@volvo/vce-uikit';
import { useSiteContext } from '../../../../../context/site';
import {
  ProductivityMainMetricDefinitionTypeInput,
  SiteMetricDefinitionFilter,
  useQuerySiteMetricDefinitions,
} from '../../../../../gql-types/generated-types-super-graph';
import { GqlErrorDisplay } from '../../../../common/error/GqlErrorDisplay';
import { SectionHeader } from '../../../../common/section-header/SectionHeader';
import { VerticalSpacing } from '../../../../common/spacing/VerticalSpacing';
import { Title } from '../../../typography/Typography';

const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 8px;
  align-items: center;
`;

interface Props {
  siteMetricDefinitionId?: string;
}

export const ConnectedFuelLevel: FC<Props> = ({ siteMetricDefinitionId }) => {
  const { t } = useTranslation();
  const { siteId } = useSiteContext();
  const response = useQuerySiteMetricDefinitions({
    variables: {
      siteId,
      filterBy: {
        status: SiteMetricDefinitionFilter.All,
        types: [
          ProductivityMainMetricDefinitionTypeInput.LevelPercent,
          ProductivityMainMetricDefinitionTypeInput.LevelVolume,
        ],
      },
    },
    onError: (error) => toast.error(<GqlErrorDisplay errors={error.graphQLErrors} />),
  });

  if (!response.data?.sites.site?.siteMetricDefinitions) {
    return null;
  }

  const siteMetric = response.data.sites.site.siteMetricDefinitions.edges.find(
    (x) => x.node.id === siteMetricDefinitionId,
  )?.node;

  const name =
    siteMetricDefinitionId && siteMetric
      ? `${siteMetric.name}${siteMetric.description ? ` (${siteMetric.description})` : undefined}`
      : '-';

  return (
    <>
      <SectionHeader
        title={t('details-panel.poi.related-metric')}
        infoText={t('details-panel.poi.related-metric-info-text')}
      />
      <VerticalSpacing spacingTop={16} />
      <Grid>
        <Title text={t('details-panel.poi.fuel-level-label')} />
        <Typography variant="subtitle1">{name}</Typography>
      </Grid>
    </>
  );
};
